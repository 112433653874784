import React, { useState } from 'react';

import { GetServerSideProps } from 'next';

import { Modal } from 'antd';

import { getTariffs } from '@/api/tariff/get-tariffs';
import { ContactUs } from '@/components/ContactUs/ContactUs';
import { Footer } from '@/components/Footer/Footer';
import {
	FishSearch,
	HeroHeader,
	StartSale,
	Tariffs,
	WarehouseSearch,
	WarehousesStatistic,
} from '@/components/MainLanding';
import { MainModalContent } from '@/components/MainModalContent/MainModalContent';
import PageScroll from '@/components/PageScroll';
import { useTranslate } from '@/hooks/use-translate';
import { DocsLinks } from '@/types/legal';
import { Tariff } from '@/types/tariff';
import { LocalStorage } from '@/utils/local-storage';
type Props = {
	docs: DocsLinks;
	tariffs?: Tariff.TariffsInfoResponse;
};

export default function MainLanding({ docs, tariffs }: Props) {
	const [isShownModal, setIsShownModal] = useState(!LocalStorage.getItem('mainModal'));
	const t = useTranslate(en);

	const handleCloseModal = () => {
		LocalStorage.setItem('mainModal', 'false');
		setIsShownModal(false);
	};

	return (
		<>
			<PageScroll>
				<PageScroll.Item id="hero-header" mode="dark">
					<HeroHeader />
				</PageScroll.Item>

				<PageScroll.Item id="warehouses-search">
					<WarehouseSearch />
				</PageScroll.Item>

				<PageScroll.Item id="fish-search" mode="dark">
					<FishSearch />
				</PageScroll.Item>

				<PageScroll.Item id="warehouses-statistic">
					<WarehousesStatistic />
				</PageScroll.Item>

				<PageScroll.Item id="start-sale">
					<StartSale />
				</PageScroll.Item>

				<PageScroll.Item id="tariffs" mode="dark">
					<Tariffs tariffsData={tariffs} />
				</PageScroll.Item>

				<PageScroll.Item id="contact-us" mode="dark">
					<ContactUs docs={docs} />
				</PageScroll.Item>
			</PageScroll>

			<Footer docs={docs} />
			<Modal
				// title={t('Ждём вас на выставке')}
				open={isShownModal}
				closable
				onCancel={handleCloseModal}
				footer={null}
			>
				<MainModalContent
					title={t('Ждём вас на выставке')}
					description={t(
						'С 17 по 19 сентября будем рады видеть вас на нашем стенде M5 в павильоне G на выставке GLOBAL FISHERY FORUM & SEAFOOD EXPO RUSSIA в Санкт-Петербурге.',
					)}
					onClose={handleCloseModal}
				/>
			</Modal>
		</>
	);
}

export const getServerSideProps: GetServerSideProps = async (context) => {
	context.res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate');

	try {
		const tariffs = await getTariffs({ lang: context.locale });

		return {
			props: {
				tariffs,
			},
		};
	} catch (error) {
		return {
			props: {
				tariffs: null,
			},
		};
	}
};

const en = {
	Ок: 'Ок',
	'С 17 по 19 сентября будем рады видеть вас на нашем стенде M5 в павильоне G на выставке GLOBAL FISHERY FORUM & SEAFOOD EXPO RUSSIA в Санкт-Петербурге.':
		'From September 17 to 19, we will be glad to see you at our stand M5 in pavilion G at the GLOBAL FISHERY FORUM & SEAFOOD EXPO RUSSIA in St. Petersburg.',
	'Ждём вас на выставке': 'We are waiting for you at the exhibition',
};
