import { FC } from 'react';

import Image from 'next/image';

import { Button } from 'antd';

import imagePopup from '@/assets/images/popup_forum_image.jpg';
import { useTranslate } from '@/hooks/use-translate';

import styles from './MainModalContent.module.scss';

type Props = {
	title: string;
	description: string;
	onClose: () => void;
};

export const MainModalContent: FC<Props> = ({ title, description, onClose }) => {
	const t = useTranslate(en);

	return (
		<div className={styles.container}>
			<div className={styles.title}>{title}</div>
			<div className={styles.image}>
				<Image className={styles.image} src={imagePopup} alt="pict" />
			</div>
			<div>{description}</div>
			<Button className={styles.button} size="large" type="primary" onClick={onClose}>
				{t('Ок')}
			</Button>
		</div>
	);
};

export const en = {
	Ок: 'Ok',
};
